const { SigningCosmWasmClient } = require ('secretjs');

// AJAX Contact js
import "./ajax-contact.js";


$(function() {
    "use strict";
    
    //===== Prealoder
    
    $(window).on('load', function(event) {
        $('.preloader').delay(500).fadeOut(500);
    });
    
    
    //===== Sticky

    $(window).on('scroll', function (event) {
        var scroll = $(window).scrollTop();
        if (scroll < 20) {
            $(".navbar-area").removeClass("sticky");
            $(".navbar-area img").attr("src", "assets/images/Trivium_w_name.svg");
        } else {
            $(".navbar-area").addClass("sticky");
            $(".navbar-area img").attr("src", "assets/images/Trivium_w_name_black.svg");
        }
    });

    
    //===== Section Menu Active

    var scrollLink = $('.page-scroll');
    // Active link switching
    $(window).scroll(function () {
        var scrollbarLocation = $(this).scrollTop();

        scrollLink.each(function () {

            var sectionOffset = $(this.hash).offset().top - 73;

            if (sectionOffset <= scrollbarLocation) {
                $(this).parent().addClass('active');
                $(this).parent().siblings().removeClass('active');
            }
        });
    });
    
    
    //===== close navbar-collapse when a  clicked

    $(".navbar-nav a").on('click', function () {
        $(".navbar-collapse").removeClass("show");
    });

    $(".navbar-toggler").on('click', function () {
        $(this).toggleClass("active");
    });

    $(".navbar-nav a").on('click', function () {
        $(".navbar-toggler").removeClass('active');
    });
    
    
    //===== Sidebar

    $('[href="#side-menu-left"], .overlay-left').on('click', function (event) {
        $('.sidebar-left, .overlay-left').addClass('open');
    });

    $('[href="#close"], .overlay-left').on('click', function (event) {
        $('.sidebar-left, .overlay-left').removeClass('open');
    });
    
    
    //===== Slick

    $('.slider-items-active').slick({
        infinite: true,
        slidesToShow: 3,
        slidesToScroll: 1,
        speed: 800,
        arrows: true,
        prevArrow: '<span class="prev"><i class="lni lni-arrow-left"></i></span>',
        nextArrow: '<span class="next"><i class="lni lni-arrow-right"></i></span>',
        dots: true,
        autoplay: true,
        autoplaySpeed: 5000,
        responsive: [
            {
                breakpoint: 1200,
                settings: {
                    slidesToShow: 3,
                }
            },
            {
                breakpoint: 992,
                settings: {
                    slidesToShow: 2,
                }
            },
            {
                breakpoint: 768,
                settings: {
                    slidesToShow: 2,
                }
            },
            {
                breakpoint: 576,
                settings: {
                    slidesToShow: 1,
                    arrows: false,
                }
            }
        ]
    });
    
    
    //===== Isotope Project 4

    $('.container').imagesLoaded(function () {
        var $grid = $('.grid').isotope({
            // options
            transitionDuration: '1s'
        });

        // filter items on button click
        $('.portfolio-menu ul').on('click', 'li', function () {
            var filterValue = $(this).attr('data-filter');
            $grid.isotope({
                filter: filterValue
            });
        });

        //for menu active class
        $('.portfolio-menu ul li').on('click', function (event) {
            $(this).siblings('.active').removeClass('active');
            $(this).addClass('active');
            event.preventDefault();
        });
    });
    
    
    //===== slick Testimonial Four
    
    $('.testimonial-active').slick({
        dots: false,
        arrows: true,
        prevArrow: '<span class="prev"><i class="lni lni-arrow-left"></i></span>',
        nextArrow: '<span class="next"><i class="lni lni-arrow-right"></i></span>',
        infinite: true,
       autoplay: true,
        autoplaySpeed: 5000,
        speed: 800,
        slidesToShow: 1,
    });
    
    
    //====== Magnific Popup
    
    $('.video-popup').magnificPopup({
        type: 'iframe'
        // other options
    });
    
    
    //===== Magnific Popup
    
    $('.image-popup').magnificPopup({
      type: 'image',
      gallery:{
        enabled:true
      }
    });
    
    
    //===== Back to top
    
    // Show or hide the sticky footer button
    $(window).on('scroll', function(event) {
        if($(this).scrollTop() > 600){
            $('.back-to-top').fadeIn(200)
        } else{
            $('.back-to-top').fadeOut(200)
        }
    });
    
    
    //Animate the scroll to yop
    $('.back-to-top').on('click', function(event) {
        event.preventDefault();
        
        $('html, body').animate({
            scrollTop: 0,
        }, 1500);
    });
    
    
    //===== Modals
    var delegateSelected = true;
    var address = "";
    var validatorFromAddress = "";
    var availableAmount = 0;
    var delegatedAmount = 0;
    var delegationsObj = [];
    var cosmJS;
    var outputTxt;
    var stakeBtnDestination = '#introModal';



    // intro
     $('.stake-btn-modal').on('click', function(event){
        $(stakeBtnDestination).modal('show');;
        checkKeplr(); //async
     });

    async function checkKeplr(){
        if (!window.getOfflineSigner || !window.keplr) { // no Keplr found
            $('.authorize-keplr').prop('disabled', true);
            $('p.keplr-output').html('Error: Keplr was not found.<br> Refresh the page after installing or enabling Keplr.');
            $('.keplr-output').css('display','block');
        }
    };

    // keplr connect
     $("#keplrModal").on('show.bs.modal', function (e) {
        $("#introModal").removeClass("fade"); 
        $("#introModal").modal("hide");
        keplrAuth();
    });

    $('.backToIntroModal').on('click', function (e){
        $("#introModal").modal("show");
        $("#keplrModal").modal("hide");

        $('.keplr-output').css('display','none');    
    })

    // stake
    $("#stakeModal").on('show.bs.modal', function (e) {

        $("#keplrModal").modal("hide");
        $('p.scrt-amount-available').html(availableAmount+" SCRT");
    });

    $('a.dropdown-unselected').on('click', function (e){
        if(delegateSelected){ // redelegate

            delegateSelected = false;
            validatorFromAddress = $('#validatorTextFrom').val();

            $('a.dropdown-unselected').html('Delegate');
            $('button.dropdown-toggle').html('Redelegate');
            $('#delegate-action').html('Redelegate');
            $('#redelegateFromDiv').css('display','block');

            return
        } else { // delegate

            delegateSelected = true;
            
            $('a.dropdown-unselected').html('Redelegate');
            $('button.dropdown-toggle').html('Delegate');
            $('#delegate-action').html('Delegate');
            $('#redelegateFromDiv').css('display','none');
        }
    });

    $('#validatorTextFrom').on('change', function() {
        validatorFromAddress = $(this).val(); 
        console.log(`new redelegated from address: ${validatorFromAddress}`)
    });

    $('#delegate-action').on('click', function(e){
        $(this).prop('disabled', true);
        delegateAsync();
     });

     $("#input-amount").on('keyup', function (e) {
        if (e.key === 'Enter') {
            delegateAsync();
        }
    });

    function moveToExitModal(cleanExit = false){        
        $('p.exit-output').html(outputTxt);
        $('.exitBtn').css('display', 'none');
        
        $("#exitModal").modal("show");
        $("#stakeModal").modal("hide");

        $('#delegate-action').prop('disabled', false);
        
        if(cleanExit){
            $('.exit-output-container').css('background-color', 'white');
            $('p.exit-output').css('color', 'black');
            $('.backToStakeModal').css('display', 'none');
            $('.exitBtn').css('display', 'block');
        };
    };
    
    // outro
    $("#exitModal").on('show.bs.modal', function (e) {
        $("#stakeModal").modal("hide");
    });

    $('.backToStakeModal').on('click', function (e){
        $("#stakeModal").modal("show");
        $("#exitModal").modal("hide");
    })


    async function keplrAuth(){
        if (window.keplr.experimentalSuggestChain) {
            try {    
                await window.keplr.enable('secret-4');

                // @ts-ignore
                const keplrOfflineSigner = await window.getOfflineSigner('secret-4');
                const accounts = await keplrOfflineSigner.getAccounts();
                                
                // import address
                address = accounts[0].address;

                cosmJS = new SigningCosmWasmClient(
                    'https://secret-4.api.trivium.network:1317',
                    address,
                    keplrOfflineSigner,
                    window.getEnigmaUtils('secret-4'),
                    {
                        init: {
                            amount: [{ amount: '300000', denom: 'uscrt' }],
                            gas: '300000',
                        },
                        exec: {
                            amount: [{ amount: '300000', denom: 'uscrt' }],
                            gas: '300000',
                        },
                    },
                );       
                const account = await cosmJS.getAccount();
                if(account){
                    // update variables & UI
                    availableAmount = getScrt(account);
                    $('p.user-address').html(address);

                    // Redelegate data
                    if(address != "") retrieveDelegationsInfo();

                    // Go to next modal (& remove the intro modal from html)                        
                    $('#stakeModal').modal('show');
                    $('#keplrModal').modal('hide');

                    stakeBtnDestination = '#stakeModal';
                }
                
            } catch (error) {
                $('p.keplr-output').html(`Error: ${error}`);
                $('.keplr-output').css('display','block');    
                console.error(error)
            }
        }
    };

    async function retrieveDelegationsInfo(){
        // Get all delegations
        $.get(`https://secret-4.api.trivium.network:1317/staking/delegators/${address}/delegations`, function(data){

            // total delegated
            delegatedAmount = data.result.reduce(function (accumulator, validator){
                return accumulator + (validator.balance.amount * 10**-6);
            }, 0)

            // val & stake pairs
            delegationsObj = data.result.map(function (validator){
                return [validator.validator_address, validator.balance.amount] 
            });

        }).done(() =>{
            // UI
            $('p.scrt-amount-delegated').html(delegatedAmount.toFixed(2)+" SCRT");

            // validator text name 
            delegationsObj.map(function (validator){
                $.get(`https://secret-4.api.trivium.network:1317/staking/validators/${validator[0]}`, (function(val_data){}))
                .done(function(val_data){
                    validator.push(val_data.result.description.moniker);
                    
                    // delegationsObj single array: ['secretadress','amount staked', 'moniker description']
                    $('#validatorTextFrom').append(`<option value="${validator[0]}">${validator[2]} - ${(validator[1] * 10**-6).toFixed(2)} SCRT</option>`);
                });            
            });
        });    
    };     

    async function delegateAsync(){ 
        const recipient = "secretvaloper1ahawe276d250zpxt0xgpfg63ymmu63a0svuvgw";
        
        var amount = $('#input-amount').val();
        
        // convert human input to on-chain ints
        amount = parseFloat(amount);
        if (isNaN(amount) || amount == 0) {
            $('#delegate-action').prop('disabled', false);
            outputTxt = ('Not a valid input value');
            moveToExitModal(); 
            return false;
        }
        amount *= 1000000;
        amount = Math.floor(amount);
        
        // define memo (not required)
        const memo = 'Delegation to Trivium Node';

        // Msg
        var sendMsg;
        if(!delegateSelected){
            // redelegation
            console.log(`Delegation: ${delegateSelected}`, `FromVal: ${validatorFromAddress}, For: ${amount}`);
            
            // define msg
            sendMsg = {
                type: 'cosmos-sdk/MsgBeginRedelegate',
                value: {
                    delegator_address: address,
                    validator_src_address: validatorFromAddress,
                    validator_dst_address: recipient,
                    amount: {
                        denom: 'uscrt',
                        amount: amount.toString(),
                    },
                },
            };
        
        } else {
            // delegation
            
            // define msg
            sendMsg = {
                type: 'cosmos-sdk/MsgDelegate',
                value: {
                    delegator_address: address,
                    validator_address: recipient,
                    amount: {
                        denom: 'uscrt',
                        amount: amount.toString(),
                    },
                },
            };

        }
    
        // define fees
        const fee = {
            /*amount: [
              {
                amount: '50000',
                denom: 'uscrt',
              },
            ],*/
            gas: '40000',
          };
          
        // sign transaction
        const { accountNumber, sequence } = await cosmJS.getNonce(address)
            .catch((err) => {
                outputTxt = (`Could not get nonce: ${err}`);
                moveToExitModal(); 
                return 
            });
        
        const signedTx = await cosmJS.signAdapter([sendMsg], fee, 'secret-4', memo, accountNumber, sequence)
            .catch((err) => { 
                outputTxt = (`Could not sign Tx: ${err}`);
                console.log(err); 
                moveToExitModal();
                return
            });
        if(signedTx == undefined) return;

        // broadcast TX
        const result = await cosmJS.postTx(signedTx)
            .catch((err) => { 
                outputTxt = (`Could not post tx: ${err}`); 
                moveToExitModal();
                return
            });
        
        if (result.code !== undefined &&
            result.code !== 0) {
                outputTxt = result.log || result.rawLog;
                moveToExitModal();
                return

        } else {
            console.log("Successfully sent tx: https://secretnodes.com/secret/chains/secret-4/transactions/" + result.transactionHash);
            outputTxt = "Delegation succesful. Thank you for your delegation! Let us know you are staking with us, we'd love to get to know you!";
            moveToExitModal(true);
            return
        };
    };

    function getScrt(account) {
        if (account === undefined) {
            return 0
        } else {
            const balance = account.balance.find(isDenomScrt);
            let amount = 0;
            if (balance) {
                amount = balance.amount > 0 ? balance.amount / 10**6 : 0;
            }
            return amount.toFixed(2);
        }
    }
    function isDenomScrt(balance) {
        return balance.denom === 'uscrt';
    }    
    
    
    // helper
    $.fn.inputFilter = function(inputFilter) {
        return this.on("input keydown keyup mousedown mouseup select contextmenu drop", function() {
          if (inputFilter(this.value)) {
            this.oldValue = this.value;
            this.oldSelectionStart = this.selectionStart;
            this.oldSelectionEnd = this.selectionEnd;
          } else if (this.hasOwnProperty("oldValue")) {
            this.value = this.oldValue;
            this.setSelectionRange(this.oldSelectionStart, this.oldSelectionEnd);
          } else {
            this.value = "";
          }
        });
    };

});

$(document).ready(function() {
    $("#input-amount").inputFilter(function(value) {
      return /^\d*(\.\d*)?$/.test(value);    // Allow digits only, using a RegExp
    });
  });